<i18n src="@/locales/useful_dental_care_sofia.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="sofia-dental-tourism">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        <span v-html="$t('message_1')" />
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_p1') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_p2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_4') }}
        </h3>
        <p>
          <span v-html="$t('article_p4')" />
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_5') }}
        </h3>
        <p>
          {{ $t('article_p5') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <b-message
          type="is-info"
        >
          <p>
            {{ $t('message_2') }}
          </p>
        </b-message>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_6') }}
        </h3>
        <p>
          {{ $t('article_p6') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_7') }}
        </h3>
        <p>
          {{ $t('article_p7') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <b-message
          type="is-info"
        >
          <h3 class="has-text-danger is-size-4">
            {{ $t('h3_title_8') }}
          </h3>
          <p>
            {{ $t('message_3') }}
          </p>
        </b-message>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_9') }}</ImanH3>
        <p>
          {{ $t('article_p8') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_10') }}</ImanH3>
        <p>
          {{ $t('article_p9') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_11') }}</ImanH3>
        <p>
          {{ $t('article_p10') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[4]">
        <ImanH3>{{ $t('h3_title_12') }}</ImanH3>
        <p>
          {{ $t('article_p11') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[5]">
        <ImanH3>{{ $t('h3_title_13') }}</ImanH3>
        <p>
          {{ $t('article_p12') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[6]">
        <ImanH3>{{ $t('h3_title_14') }}</ImanH3>
        <p>
          {{ $t('article_p13') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[7]">
        <ImanH3>{{ $t('h3_title_15') }}</ImanH3>
        <p>
          {{ $t('article_p16') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[8]">
        <ImanH3>{{ $t('h3_title_16') }}</ImanH3>
        <p>
          {{ $t('article_p17') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[9]">
        <ImanH3>{{ $t('h3_title_17') }}</ImanH3>
        <p>
          {{ $t('article_p18') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2_1') }}
          </p>
          <div v-html="$t('collapse_p2_2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p4') }}
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'SofiaDentalTourism',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [531, 533, 534],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'clinic/bulgaria/orthodontie-etranger.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/theatre-sofia.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/Eglise-russe-sofia2.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/quartier-presidentiel.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/eglise-centre-sofia.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/cathedrale-sofia.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/mosquee-sofia.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/palais-de-la-culture-sofia-ndk.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/sofia-nightlife-bars-discotheques.jpg',
            position: 'right'
          },
          {
            path: 'useful/useful-dental-care/sofia/centre-commerciaux-sofia.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/Z5q4zW0CdNQ'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_5.3.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.header-background {
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../../assets/img/useful/useful-dental-care/sofia/H1-tourisme-dentaire-bulgarie.jpg");
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../../assets/img/useful/useful-dental-care/sofia/H1-tourisme-dentaire-bulgarie.jpg");
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    /* smartphone en mode portait */
    background-image: url("../../../assets/img/useful/useful-dental-care/sofia/H1-mobile-tourisme-dentaire-bulgarie.jpg");
  }
}
</style>
